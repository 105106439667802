import { Outlet } from 'react-router-dom';
import i18next from 'i18next';
import PaymentReport from '../../views/Reports/Payment/Listing';
import MembershipHistoryList from '../../views/Reports/MembershipHistory/Listing/index';

import {
  JobProviderDiscount,
  PaymentBookingLog,
  PaymentMethods,
  ProviderInvoiceUpdate,
  PurchaseOrder,
  ReturnAmountDetails,
  SplitPaymentDetails,
  SubLaborPayAdd,
  SubLaborPayDetails,
} from '../../views/Reports/Payment';
import {
  NCABillingReportList,
  NCABillingReportDetails,
  ReviewReportList,
} from '../../views/Reports';
import { ServiceProvidersReportList } from '../../views/Reports';
import { getActions } from '../../services/index.service';
import CommonInvoicePage from '../../features/InvoicePage';

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Reports',
      key: 'report',
      belongsToSidebar: true,
      submenu: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-file-text-fill" />
        </span>
      ),
      moduleKeys: [
        accessRoute.PAYMENT_REPORT.moduleKey,
        accessRoute.SERVICE_PROVIDER_PAYMENT.moduleKey,
        accessRoute.REVIEWS.moduleKey,
        accessRoute.NCA_BILLING_REPORT.moduleKey,
        accessRoute.MEMBERSHIP_HISTORY.moduleKey,
      ],
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.PAYMENT_REPORT.path,
          name: 'Payment Report',
          key: 'report.list',
          belongsToSidebar: true,
          element: <PaymentReport />,
          ...accessRoute.PAYMENT_REPORT,
        },
        {
          private: true,
          path: accessRoute.JOB_PROVIDER_DISCOUNT.path,
          name: 'Job Provider Discount',
          key: 'report.list',
          belongsToSidebar: false,
          element: <JobProviderDiscount />,
          ...accessRoute.JOB_PROVIDER_DISCOUNT,
        },
        {
          private: true,
          path: accessRoute.PURCHASE_ORDER_ADD.path,
          name: 'Purchase Order Details',
          key: 'report.list',
          belongsToSidebar: false,
          element: <PaymentReport />,
          ...accessRoute.PURCHASE_ORDER_ADD,
        },
        {
          private: true,
          path: accessRoute.SUBLABOR_PAY_ADD.path,
          name: 'Sub Labour Pay',
          key: 'report.list',
          belongsToSidebar: false,
          element: <SubLaborPayAdd />,
          ...accessRoute.SUBLABOR_PAY_ADD,
        },
        {
          private: true,
          path: accessRoute.RETURN_AMOUNT_ADD.path,
          name: 'Return Amount',
          key: 'report.list',
          belongsToSidebar: false,
          element: <ReturnAmountDetails />,
          ...accessRoute.RETURN_AMOUNT_ADD,
        },
        {
          private: true,
          path: accessRoute.PURCHASE_ORDER.path,
          name: 'Purchase Order',
          key: 'report.list',
          belongsToSidebar: false,
          element: <PurchaseOrder />,
          ...accessRoute.PURCHASE_ORDER,
        },
        {
          private: true,
          path: accessRoute.INVOICE.path,
          name: 'Invoice',
          key: 'report.list',
          belongsToSidebar: false,
          element: <CommonInvoicePage />,
          ...accessRoute.INVOICE,
        },
        {
          private: true,
          path: accessRoute.SPLIT_PAYMENT.path,
          name: 'Split Payment',
          key: 'report.list',
          belongsToSidebar: false,
          element: <SplitPaymentDetails />,
          ...accessRoute.SPLIT_PAYMENT,
        },
        {
          private: true,
          path: accessRoute.PROVIDER_INVOICE_UPDATE.path,
          name: 'Provider Invoice Update',
          key: 'report.list',
          belongsToSidebar: false,
          element: <ProviderInvoiceUpdate />,
          ...accessRoute.PROVIDER_INVOICE_UPDATE,
        },
        {
          private: true,
          path: accessRoute.PAYMENT_METHOD.path,
          name: 'Payment Method',
          key: 'report.list',
          belongsToSidebar: false,
          element: <PaymentMethods />,
          ...accessRoute.PAYMENT_METHOD,
        },
        {
          private: true,
          path: accessRoute.SERVICE_PROVIDER_PAYMENT.path,
          name: i18next.t('text.report.serviceProviderPaymentReport'),
          key: 'report.service-provider',
          belongsToSidebar: true,
          element: <ServiceProvidersReportList />,
          ...accessRoute.SERVICE_PROVIDER_PAYMENT,
        },

        {
          private: true,
          path: accessRoute.REVIEWS.path,
          name: 'Reviews',
          belongsToSidebar: true,
          key: 'report.reviews',
          element: <ReviewReportList />,
          ...accessRoute.REVIEWS,
        },

        // {
        //   private: true,
        //   path: `${accessRoute.SERVICE_PROVIDER_PAYMENT.path}/:userId`,
        //   name: 'Service Provider Payment',
        //   key: `${accessRoute.SERVICE_PROVIDER_PAYMENT.path}/:userId`,
        //   belongsToSidebar: false,
        //   element: <ServiceProvidersReportDetails />,
        //   ...accessRoute.SERVICE_PROVIDER_PAYMENT,
        // },
        {
          private: true,
          path: accessRoute.NCA_BILLING_REPORT.path,
          name: 'NCA Billing Report',
          key: 'report.nca',
          belongsToSidebar: true,
          element: <NCABillingReportList />,
          ...accessRoute.NCA_BILLING_REPORT,
        },
        {
          private: true,
          name: 'NCA Billing Report',
          key: accessRoute.NCA_BILLING_REPORT_DETAILS.key,
          path: accessRoute.NCA_BILLING_REPORT_DETAILS.path,
          belongsToSidebar: false,
          element: <NCABillingReportDetails />,
          ...accessRoute.NCA_BILLING_REPORT_DETAILS,
        },
        {
          private: true,
          path: `${accessRoute.SUBLABOR_PAY_ADD.path}/:subLaburId`,
          name: 'Sub Labour Details',
          key: `${accessRoute.SUBLABOR_PAY_ADD.path}/:subLaburId`,
          belongsToSidebar: false,
          element: <SubLaborPayDetails />,
          ...accessRoute.SUBLABOR_PAY_ADD,
        },
        {
          private: true,
          path: `${accessRoute.BOOKING_VIEW.path}`,
          name: 'View Booking',
          key: `${accessRoute.BOOKING_VIEW.path}`,
          belongsToSidebar: false,
          element: <PaymentBookingLog />,
          ...accessRoute.BOOKING_VIEW,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_HISTORY.path,
          name: accessRoute.MEMBERSHIP_HISTORY.name,
          key: accessRoute.MEMBERSHIP_HISTORY.key,
          belongsToSidebar: true,
          element: <MembershipHistoryList />,
          ...accessRoute.MEMBERSHIP_HISTORY,
        },
      ],
    },
  ];
}

export const accessRoute = {
  PAYMENT_REPORT: {
    path: '/admin/payment-report',
    name: 'Payment Report',
    key: 'report.payment-report',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    order: 13,
  },

  JOB_PROVIDER_DISCOUNT: {
    name: 'Job Provider Discount',
    path: '/admin/payment-report/job-provider-discount',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    key: 'payment-report',
  },
  PURCHASE_ORDER_ADD: {
    name: 'Add Line Items',
    path: '/admin/payment-report/purchase-order-add',
    action: getActions('CREATE').value,
    moduleKey: 'payment-report',
    key: 'payment-report',
  },
  SUBLABOR_PAY_ADD: {
    name: 'Edit Line Items',
    path: '/admin/payment-report/sublabor-pay-add',
    action: getActions('CREATE').value,
    moduleKey: 'payment-report',
    key: 'payment-report',
  },
  RETURN_AMOUNT_ADD: {
    path: '/admin/payment-report/return-amount-add',
    action: getActions('CREATE').value,
    moduleKey: 'payment-report',
    name: 'Edit Line Items',
    key: 'payment-report',
  },
  RETURN_AMOUNT: {
    path: '/admin/payment-report/return-amount',
    action: getActions('CREATE').value,
    moduleKey: 'payment-report',
    name: 'Edit Line Items',
    key: 'payment-report',
  },
  PURCHASE_ORDER: {
    path: '/admin/payment-report/purchase-order',
    action: getActions('CREATE').value,
    moduleKey: 'payment-report',
    name: 'Edit Line Items',
    key: 'payment-report',
  },
  INVOICE: {
    path: '/admin/payment-report/invoice/:id',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    key: 'payment-report',
    name: 'Edit Line Items',
  },
  SPLIT_PAYMENT: {
    path: '/admin/payment-report/split-payment',
    action: getActions('CREATE').value,
    moduleKey: 'payment-report',
    name: 'Edit Line Items',
    key: 'payment-report',
  },
  PROVIDER_INVOICE_UPDATE: {
    path: '/admin/payment-report/provider-invoice-update',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    name: 'Edit Line Items',
    key: 'payment-report',
  },
  PAYMENT_METHOD: {
    path: '/admin/payment-report/payment-method',
    action: getActions('LIST').value,
    moduleKey: 'payment-report',
    name: 'Payment Method',
  },
  NCA_BILLING_REPORT: {
    path: '/admin/nca-billing-report',
    action: getActions('LIST').value,
    moduleKey: 'nca-billing-report',
    key: 'report.nca-billing-report',
    order: 52,
  },
  NCA_BILLING_REPORT_DETAILS: {
    path: '/admin/nca-billing-report/view/:id',
    action: getActions('LIST').value,
    moduleKey: 'nca-billing-report',
    key: 'report.nca-billing-report',
  },
  REVIEWS: {
    path: '/admin/review',
    action: getActions('LIST').value,
    moduleKey: 'reviews',
    key: 'report.reviews',
    order: 12,
  },
  SERVICE_PROVIDER_PAYMENT: {
    path: '/admin/service-provider-payment',
    action: getActions('LIST').value,
    moduleKey: 'service-provider-payment-report',
    key: 'report.service-provider-payment',
    order: 14,
  },
  BOOKING_VIEW: {
    path: '/admin/payment-report/booking/:id/:viewQuote',
    moduleKey: 'payment-report',
    key: 'payment-report',
    action: getActions('LIST').value,
  },
  MEMBERSHIP_HISTORY: {
    path: '/admin/membership/history',
    action: getActions('LIST').value,
    key: 'report.membershipHistory',
    name: 'Membership Payment Report',
    moduleKey: 'membership-history',
    order: 31,
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
