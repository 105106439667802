import loadable from '@loadable/component';
import FullPageLoader from '../UIElements/Loader/fullPageLoader';

export const NCALoginForm = loadable(
  () => import('./Auth/Login/ncaLogin.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AdminLoginForm = loadable(
  () => import('./Auth/Login/adminLogin.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ChangePasswordForm = loadable(
  () => import('./Auth/ChangePassword/changePassword.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export * from './Auth/Register/index.form';

export const InformationForm = loadable(
  () => import('./AppText/Information/information.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const TipsForm = loadable(() => import('./AppText/Tips/tips.form'), {
  fallback: <FullPageLoader />,
});
export const FinancingInformationForm = loadable(
  () => import('./AppText/Financing/financing.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddRoleForm = loadable(
  () => import('./SuperAdmin/Role/role.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddIndustryForm = loadable(
  () => import('./Industry/addIndustry.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const QuoteInformationForm = loadable(
  () => import('./AppText/QuoteInformation/quoteInformation.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AssignModuleFilterForm = loadable(
  () => import('./SuperAdmin/Role/assign.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddCityForm = loadable(() => import('./Localization/city.form'), {
  fallback: <FullPageLoader />,
});
export const AddCountryForm = loadable(
  () => import('./Localization/country.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditLocationForm = loadable(
  () => import('./ManageLocation/addEditLocation.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddStateForm = loadable(
  () => import('./Localization/state.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddAdminForm = loadable(
  () => import('./SuperAdmin/Admin/addAdmin.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditUserAddressForm = loadable(
  () => import('./Customers/addEditUserAddress.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditUserForm = loadable(
  () => import('./Customers/addEditUser.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const PageForm = loadable(() => import('./Page/page.form'), {
  fallback: <FullPageLoader />,
});
export const AddFaqForm = loadable(() => import('./Faq/faq.form'), {
  fallback: <FullPageLoader />,
});

export const AddBannerForm = loadable(
  () => import('./CustomerText/banner.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const NotificationForm = loadable(
  () => import('./CustomerText/notification.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditHomeForm = loadable(() => import('./EditHome/editHome.form'), {
  fallback: <FullPageLoader />,
});
export const AddProviderForm = loadable(
  () => import('./Providers/addProvider.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditAvailabilityForm = loadable(
  () => import('./Providers/editAvailability.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const unavailabilityModalForm = loadable(
  () => import('./Providers/unavailabilityModal.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditCompanyProfileForm = loadable(
  () => import('./Providers/editCompanyProfile.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditBusinessDetailsForm = loadable(
  () => import('./Providers/editBusinessDetails.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditBankingDetailsForm = loadable(
  () => import('./Providers/bankingDetails.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditLineItemsForm = loadable(
  () => import('./LineItems/addEditLineItems.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ProviderDocumentForm = loadable(
  () => import('./Providers/document.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const TagForm = loadable(() => import('./Tags/tag.form'), {
  fallback: <FullPageLoader />,
});
export const SendEmailForm = loadable(() => import('./SendEmail/email.form'), {
  fallback: <FullPageLoader />,
});
export const OptionalItemForm = loadable(
  () => import('./QuoteManagement/OptionalItem.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ServiceTypeForm = loadable(
  () => import('./PriceManagement/ManageServices/serviceType.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditPromoCodeForm = loadable(
  () => import('./Marketing/PromoCode/addEditPromoCode.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ChangeCustomerPasswordForm = loadable(
  () => import('./CustomerApp/changePassword.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ServiceCategoryForm = loadable(
  () => import('./PriceManagement/ManageServices/serviceCategory.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CheckAndFinanceForm = loadable(
  () => import('./Report/Payment/checkFinance.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const CreditCard = loadable(
  () => import('./Report/Payment/creditCard.from'),
  {
    fallback: <FullPageLoader />,
  }
);
export const FinanceBankForm = loadable(
  () => import('./PriceManagement/Financing/addOrEditBank.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const FinanceOptionForm = loadable(
  () => import('./PriceManagement/Financing/addOrEditOption.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ServiceSubcategoryForm = loadable(
  () => import('./PriceManagement/ManageServices/serviceSubcategory.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const EditEmailForm = loadable(
  () => import('./EmailMessage/Email/editEmail.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const EditSmsForm = loadable(
  () => import('./EmailMessage/Sms/editSms.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddOrEditQuoteSystemForm = loadable(
  () => import('./QuoteManagement/QuoteSystem/addOrEditQuoteSystem.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const EditGeneralForm = loadable(
  () => import('./CompanySetting/General/editGeneral.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const CaseAndAdminForm = loadable(
  () => import('./Report/Payment/cashAndAdminCommon.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomerLoginForm = loadable(
  () => import('./Auth/Login/customerLogin.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomerSignUpForm = loadable(
  () => import('./Auth/SignUp/customerSignUp.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomerForgetPasswordForm = loadable(
  () => import('./Auth/ForgetPassword/index'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CustomerResetPasswordForm = loadable(
  () => import('./Auth/ResetPassword/index'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddAddressForm = loadable(
  () => import('./Address/createAddress.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const QuoteOrderForm = loadable(
  () => import('./QuoteManagement/QuoteOrder/quoteOrder.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddOrEditRebateForm = loadable(
  () => import('./QuoteManagement/Rebates/rebates.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddOrEditAmountCalculationForm = loadable(
  () => import('./QuoteManagement/AmountCalculation/amountCalculation.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddOrEditSupplyHouseForm = loadable(
  () => import('./QuoteManagement/SupplyHouse/supplyHouse.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const GoogleBudget = loadable(
  () => import('./GoogleConnect/GoogleAdwards/Budget/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const PhotoAddForm = loadable(
  () => import('./GoogleConnect/PostPhoto/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditLessonForm = loadable(
  () => import('./OperatingProcedure/addEditLesson.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const QuestionForm = loadable(
  () => import('./OperatingProcedure/AddQuestionForm/addQuestion.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ReviewPostForm = loadable(
  () => import('./GoogleConnect/Review/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const DocumentNameForm = loadable(
  () => import('./ManageDocument/addEditDocumentName.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditStoreForm = loadable(
  () => import('./Marketing/Store/store.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const EditProfileForm = loadable(
  () => import('./EditNca/Profile/editProfile.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const CompanyProfileForm = loadable(
  () => import('./EditNca/CompanyProfile/companyProfile.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const SetupCardDetailsForm = loadable(
  () => import('./EditNca/SetupCardDetails/setupCardDetails.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const TruckOrderForm = loadable(
  () => import('./TruckOrder/truckOrder.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const StoreLoginForm = loadable(
  () => import('./Auth/Login/storeLogin.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StorePromoCode = loadable(
  () => import('./Store/PromoCodeForm/PromoCode.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreSignUpForm = loadable(
  () => import('./Auth/SignUp/storeSignUp.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ContactUsForm = loadable(() => import('./ContactUs/index.form'), {
  fallback: <FullPageLoader />,
});
export const StoreForgetPasswordForm = loadable(
  () => import('./Auth/ForgetPassword/storeForgetPassword.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreResetPasswordForm = loadable(
  () => import('./Auth/ResetPassword/storeResetPassword.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const TruckOrderFilter = loadable(
  () => import('./TruckOrder/TruckOrderFilter/truckOrderFilter.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreEditProfile = loadable(
  () => import('./Store/EditProfile/editProfile.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreEditAddress = loadable(
  () => import('./Store/EditAddress/editAddress.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreChangePasswordForm = loadable(
  () => import('./Store/ChangePassword/changePassword.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreEditBankDetails = loadable(
  () => import('./Store/EditBankDetails/editBank.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreAddQuestion = loadable(
  () => import('./Store/AddQuestion/addQuestion.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreAddPromoCode = loadable(
  () => import('./Store/AddPromoCode/addPromoCode.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const StoreUpdateProfile = loadable(
  () => import('./Store/UpdateProfileForm/updateProfile.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AdminProfileForm = loadable(
  () => import('./AdminProfile/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CancelBookingForm = loadable(
  () => import('./CancelBooking/cancelBooking.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ScheduleFilterForm = loadable(
  () => import('./ScheduleBooking/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const PaymentReportFilterForm = loadable(
  () => import('./PaymentReport/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);
export const PushNotificationForm = loadable(
  () => import('./PushNotificationForm/pushNotificationForm.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const NcaProfile = loadable(() => import('./NcaProfile/index'), {
  fallback: <FullPageLoader />,
});

export const CompletePaymentDetails = loadable(
  () => import('./Report/Payment/completePaymentDetails'),
  {
    fallback: <FullPageLoader />,
  }
);
export const CheckoutAsGuest = loadable(
  () => import('./CheckoutAsGuest/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddMarketingEmailForm = loadable(
  () => import('./Marketing/MarketingEmailTemplate/addMarketingEmail.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddEditSMSTemplateForm = loadable(
  () =>
    import(
      './Marketing/MarketingTemplate/MarketingSMSTemplate/addEditMarketingSms.form'
    ),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditMembershipForm = loadable(
  () => import('./Membership/addMembership.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const MembershipHistoryFilterForm = loadable(
  () => import('./Membership/index.filter'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddEditCoupon = loadable(
  () => import('./ShoppingCart/CreateCoupon/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditCategoryForm = loadable(
  () => import('./ManageCategory/category.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditSubCategoryForm = loadable(
  () => import('./ManageCategory/subCategory.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddEditProductForm = loadable(
  () => import('./ShoppingCart/CreateProduct/index.form'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddEditVariantForm = loadable(
  () => import('./ShoppingCart/VariantManagement/variant.form'),
  {
    fallback: <FullPageLoader />,
  }
);

export * from './Marketing/Advertisement';
export const AdminNote = loadable(
  () => import('./VoiceAIRecord/adminNote.form'),
  {
    fallback: <FullPageLoader />,
  }
);
