export const text = {
  heading: 'Membership Plan',
  membershipHisotryHeading: 'Membership Payment Report',
  addMembershipPlan: 'Add Membership Plan',
  editMembershipPlan: 'Edit Membership Plan',
  enterPlanName: 'Enter plan name',
  enterDisplayOrder: 'Enter display order',
  planName: 'Plan Name',
  discountOrder: 'Discount Order',
  monthlyOption: 'Monthly Option',
  yearlyOptions: 'Yearly Options',
  monthlyCost: 'Monthly Cost',
  enterMonthlyCost: 'Enter Monthly Cost',
  yearlyCost: 'Yearly Cost',
  enterYearlyCost: 'Enter Yearly Cost',
  typesOfBenefit: 'Type Of Benefit',
  selectType: 'Select type of benefit',
  selectSubCategoryService: 'Select the Sub Category/Old Quote Services',
  selectSubCategoryPlaceholder: 'Select the sub category/old quote services',
  typeOfSubCategorySaving: 'Type of Sub Category/Old Quote Saving',
  savingAmount: 'Saving Amount',
  description: 'Description',
  enterSavingAmount: 'Enter saving amount',
  selectDiscount: 'Select the Discount From Quote System',
  selectDiscountPlaceholder: 'Select the discount from quote system',
  quoteSaving: 'New Sales Quote Saving',
  lineItemSaving: 'Line Item Saving',
  yearlyOneTime: 'Yearly One Time Cost',
  subCategoryDiscount: 'Sub Category Discount',
  lineItemDiscount: 'Line Item Discount',
  quoteDiscount: 'Quote Discount',
  planType: 'Plan Type',
  lengthOfMembership: 'Length of Membership',
  displayOrder: 'Display Order',
  discountPlaceholder: 'addition discount',
  lengthOfMembershipPlaceholder: 'length of membership',
  displayOrderPlaceholder: 'display order',
  selectAtLeastOnePlan: 'Please choose at least one option',
  memberShipCustomer: 'Customer Details',
  areYouSureToDelete: 'Are you sure to delete membership',
  lineItemSavingAmount:"Line Item Saving Amount",
  quoteSavingAmount:"Quote Saving Amount",
  comboPlankey:"Combo",
  lineItemPlankey:"Line Item",
  quotePlankey:"Quote",
  serviceCategoryPlankey:"Service Category",
  jobNo :"Job No.",
  customerName :"Customer Name",
  purchasedBy :"Purchased By",
  purchasedDate:"Purchased Date",
  membershipType:"Membership Type",
  planDuration:"Plan Duration",
  planAmount:"Plan Amount",
  paymentMethod:"Payment Method",
  validityDate:"Validity Date",
  paymentStatus:"Payment Status",
  youWantToSettle:"You want to settle this payment.",
  addressRequiredMessage:'Please add address to be purchase membership',
};
