import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  CommonButton,
  ModalWrapper,
  PageContainerWrapper,
  MembershipHistoryFilterForm,
  SendEmailForm,
  SweetAlert,
  TablePlus,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import {
  Columns,
  getActions,
  getMembershipHistoryAmountDetailsService,
  getMembershipHistoryService,
  settleMembershipPaymentService,
  sendRecieptEmailService,
} from '../../../../services/index.service';
import useTable from '../../../../hooks/useTable.hook';
import { dateFormatter, decodeQueryData, logger, modalNotification } from '../../../../utils';
import { PermissionContext } from '../../../../context/permission.context';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Checkbox, Spin, Table } from 'antd';
import { checkValidPrice } from '../../../../components/UIElements/Formatter';
import { getApiData } from '../../../../hooks/useGetApi.hook';
import { getSelectDateFilterData } from '../../../../services/Columns';
import moment from 'moment';
import { updateMarkAsSettledMembershipHistoryService } from '../../../../services/Membership/membership.service';
function MembershipHistoryList() {
  const { t } = useTranslation();
  const breadcrumb = Columns.memberShipHistoryBreadcrumb();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryData = Object.fromEntries([...searchParams]);
  const permissionContext = useContext(PermissionContext);
  const [arrayValue, setArrayValue] = useState([]);
  const [jobAmountDetails, setJobAmountDetails] = useState({});
  const [settledLoading, setSettledLoading] = useState(false);
  const [checkboxId, setCheckBoxId] = useState([]);
  const [isFirstTimeTotalAmount, setIsFirstTimeTotalAmount] = useState(true);
  const [globalFilter, setGlobalFilter] = useState(false);
  const [membershipHistoryId,setMembershipHistoryId] = useState('');
  const [settledCheck, setSettledCheck] = useState(false);
  const [state, setState] = useState({
    loader: null,
    selectedRows: [],
    submitText: 'add',
    isAlertVisible: false,
    isSettlementButtonAlert: false,
    isLoading: false,
    settleObj: null,
    isShowTotalAmount: false,
    isShowModal: null,
  });
  const location = useLocation();
  const mainFilterRef = useRef({})

  const params = location?.search
    ? decodeQueryData(location && location?.search)
    : '';
  const {
    providerLocation,
    customerId,
    customerName
  } = params;
  let extraQuery = {
    customerId: customerId ?? { customerId: [customerId] },
  };
  const [tableHandler, tableProps] = useTable(
    getMembershipHistoryService,
    extraQuery = customerId ? extraQuery : {},
    "",
    false,
    false,
    false,
    false,
    true,
    false,
    mainFilterRef.current
  );
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    onTableSort,
    onFilterChange,
    onFilterResetChange,
  } = tableHandler;
  const [isSearch, setIsSearch] = useState(0);
  const [filterValue, setFilter] = useState({
    purchaseFrom: filter?.purchaseFrom || '',
    purchaseTo: filter?.purchaseTo || '',
    location: providerLocation ? parseInt(providerLocation) : undefined,
    dateType: (getSelectDateFilterData[0]?.value) || undefined,
    jobNumber: filter?.jobNumber || undefined,
    customerName: filter?.customerName || undefined,
    customerEmail: filter?.customerEmail || undefined,
    phoneNumber: filter?.phoneNumber || undefined,
    purchasedBy: filter?.purchasedBy || undefined,
    membershipType: filter?.membershipType || undefined,
    planType: filter?.planType || undefined,
    durationType: filter?.durationType || undefined,
    planAmount: filter?.planAmount || undefined,
    paymentMethod: filter?.paymentMethod || undefined,
    paymentStatus: filter?.paymentStatus || undefined,
  });

  const [values, setValues] = useState({
    ...filter,
    purchaseFrom: '',
    purchaseTo: '',
    dateType: undefined,
    location: undefined,
  });

  useEffect(() => {
    if (queryData?.page || queryData?.pageSize) {
      setCheckBoxId([]);
      setArrayValue([]);
    }
  }, [queryData?.page, queryData?.pageSize]);

  useEffect(() => {
    setFilter({
      ...filter,
      purchaseFrom:
        values?.purchaseFrom ||
        (values?.dateType && moment().format('YYYY-MM-DD')),
      purchaseTo:
        values?.purchaseTo || (values?.dateType && moment().format('YYYY-MM-DD')),
      dateType: values?.dateType,
      location: values?.location,
    });
  }, [values]);

  useEffect(() => {
    if (data && data?.length) {
      let isCheckSettled =
        data?.length &&
        data?.filter((z) =>
          z?.settle === 0)?.length;
      setSettledCheck(isCheckSettled > 0);
    }
  }, [data]);

  useEffect(() => {
    if (filter) {
      getMembershipAmountDetails(filter);
    }
  }, [filter]);

  const onCheckAllChange = (e) => {
    arrayValue.push(e?.target?.value);
    let finalArray = [...new Set(arrayValue)];
    if (e?.target?.checked) {
      if (e?.target?.value === 'all') {
        let membershipId = data?.map((item) => {
          if (item?.settle === 0) {
            return item?.id;
          }
        });
        let arr = membershipId.filter((item) => item !== undefined);
        arr.push(e?.target?.value);
        setCheckBoxId(arr);
      } else {
        setCheckBoxId(finalArray);
      }
    } else {
      if (e?.target?.value === 'all') {
        setCheckBoxId([]);
        setArrayValue([]);
      } else {
        let arr = checkboxId?.filter(
          (item) => !['all', e?.target?.value].includes(item)
        );
        setArrayValue(arr);
        setCheckBoxId(arr);
      }
    }
    if (checkboxId.includes('all')) {
      if (e?.target?.value === 'all') {
        setCheckBoxId([]);
        setArrayValue([]);
      } else {
        let arr = checkboxId?.filter(
          (item) => !['all', e?.target?.value].includes(item)
        );
        setCheckBoxId(arr);
        setArrayValue(arr);
      }
    }
  };
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  const BackBtnFilter = () => {
    setGlobalFilter(false);
  };

  const onFilterSubmit = (values) => {
    try {
      if (!values?.purchaseFrom && !values?.purchaseTo && !values?.location) {
        modalNotification({
          type: 'error',
          message: t('text.common.selectDateLocation'),
        });
        return;
      }
      let val = {
        ...filter,
        purchaseFrom: values?.purchaseFrom || '',
        purchaseTo: values?.purchaseTo || '',
        location: values?.location || '',
        dateType: values?.dateType || '',
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      mainFilterRef.current = val;
      setIsSearch(isSearch + 1);
      onFilterChange(val);
      getMembershipAmountDetails();
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };
  function onReset() {
    setCheckBoxId([]);
    setArrayValue([]);

    const resetData = {
      ...filter,
      purchaseFrom: '',
      purchaseTo: '',
      location: undefined,
      dateType: '',
    };
    setValues((prevValues) => ({
      ...prevValues,
      ...resetData,
    }));
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        ...resetData,
      };
    });
    mainFilterRef.current = resetData;
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }

  function onTableResetFilter() {
    if (customerId || customerName) {
      searchParams.delete('customerId');
      searchParams.delete('customerName');
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
    setSearchParams(searchParams);
    setCheckBoxId([]);
    setArrayValue([]);
    setValues({});
    setFilter({});
    setIsSearch(isSearch + 1);
    // onFilterChange(resetData);
    onFilterResetChange();
  }

  window.addEventListener('resize', () => {
    const { width } = window.screen;
    if (width === 1200) {
      setState({ ...state, shouldVisible: true });
    }
  });
  const getTotalJobsAmountDetailsData = () => {
    let planTypeFilterDataArr = [];

    const totalAmountColumns = [
      {
        id: '1.',
        amount: 0,
        service: 'Sub Category Plan',
      },
      {
        id: '2.',
        amount: 0,
        service: 'Combine Item Plan',
      },
      {
        id: '3.',
        amount: 0,
        service: 'Quote Itme Plan',
      },
      {
        id: '4.',
        amount: 0,
        service: 'Line Item Plan',
      },
      {
        id: '5.',
        amount: 0,
        service: 'Total',
      },
    ];
    switch (filter?.planType) {
      case 'line_item': {
        planTypeFilterDataArr.push(totalAmountColumns[3], totalAmountColumns[4])
        break;
      }
      case 'service_category': {
        planTypeFilterDataArr.push(totalAmountColumns[0], totalAmountColumns[4])
        break;
      }
      case 'combo': {
        planTypeFilterDataArr.push(totalAmountColumns[1], totalAmountColumns[4])
        break;
      }
      case 'quote': {
        planTypeFilterDataArr.push(totalAmountColumns[2], totalAmountColumns[4])
        break;
      }
      default: {
        planTypeFilterDataArr.push(...totalAmountColumns)
        break;
      }
    }
    if (jobAmountDetails?.serviceCategoryTotalAmount) {
      totalAmountColumns[0].amount = parseFloat(
        jobAmountDetails?.serviceCategoryTotalAmount
      ).toFixed(2);
    }
    if (jobAmountDetails?.lineItemTotalAmount) {
      totalAmountColumns[3].amount = parseFloat(
        jobAmountDetails?.lineItemTotalAmount
      ).toFixed(2);
    }
    if (jobAmountDetails?.quoteTotalAmount) {
      totalAmountColumns[2].amount = parseFloat(
        jobAmountDetails?.quoteTotalAmount
      ).toFixed(2);
    }
    if (jobAmountDetails?.comboTotalAmount) {
      totalAmountColumns[1].amount = parseFloat(
        jobAmountDetails?.comboTotalAmount
      ).toFixed(2);
    }
    if (jobAmountDetails?.totalAmount) {
      switch (filter?.planType) {
        case 'line_item': {
          totalAmountColumns[4].amount = parseFloat(jobAmountDetails?.lineItemTotalAmount).toFixed(2)
          break;
        }
        case 'service_category': {
          totalAmountColumns[4].amount = parseFloat(jobAmountDetails?.serviceCategoryTotalAmount).toFixed(2)
          break;
        };
        case 'combo': {
          totalAmountColumns[4].amount = parseFloat(jobAmountDetails?.comboTotalAmount).toFixed(2)
          break;
        };
        case 'quote': {
          totalAmountColumns[4].amount = parseFloat(jobAmountDetails?.quoteTotalAmount).toFixed(2)
          break;
        };
        default: {
          totalAmountColumns[4].amount = parseFloat(jobAmountDetails?.totalAmount).toFixed(2)
          break;
        };
      }
    }
    return planTypeFilterDataArr;
  };

  const onSettled = async () => {
    setSettledLoading(true);
    try {
      let arr = checkboxId.filter((item) => item !== 'all');
      let bodyDataArr = arr.filter((item) => item !== undefined);
      if (bodyDataArr?.length > 0) {
        let bodyData = {
          settle: bodyDataArr,
        };
        const response = await updateMarkAsSettledMembershipHistoryService(bodyData);
        if (response?.success) {
          handleStateChange('isSettlementButtonAlert', false);
          setCheckBoxId([]);
          setArrayValue([]);
          modalNotification({
            type: 'success',
            message: response?.message,
          });
          onRefresh();
          setSettledLoading(false);
          return true;
        }
      } else {
        modalNotification({
          type: 'error',
          message: t('text.report.errorMarkAsMessage'),
        });
      }
    } catch (error) {
      console.log(error);
    }
    setSettledLoading(false);
  };

  const handleRecipt = (row) => {
    setMembershipHistoryId(row?.id)
    setState((prevState) => ({
      ...prevState,
      isShowModal: 'sendEmail',
    }))
  }

  const handleStateChange = (name, value) => {
    if (typeof name === 'string') {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        ...name,
      }));
    }
  };

  const totalJobsAmountDetailsColumn = [
    { 
      dataIndex: 'service',
      title: t('text.common.services'),
      render: (cell) => {
        if (cell === 'Total') {
          return <b>{cell}</b>;
        }
        return cell;
      },
     },
    {
      dataIndex: 'amount',
      title: t('text.common.amount'),
      render: (cell, row) => {
        if (row.service === 'Total') {
          return <b>{checkValidPrice(cell)}</b>;
        }
        return checkValidPrice(cell);
      },
    },
  ];

  const onConfirmAlert = async () => {
    try {
      handleStateChange('isLoading', true);
      const { settleObj } = state;
      const res = await settleMembershipPaymentService(settleObj?.id, {
        settle: settleObj?.settle === 1 ? 0 : 1,
      });
      if (res?.success) {
        handleStateChange('isAlertVisible', false);
        modalNotification({
          type: 'success',
          message: res?.message,
        });
        onRefresh();
        return true;
      }
    } catch (error) {
      handleStateChange('isLoading', false);
      modalNotification({
        type: 'error',
        message: error?.message,
      });
    }
  };

  const handleAlert = (value) => {
    handleStateChange({
      isAlertVisible: true,
      settleObj: value,
    });
  };
  const handleMarkAsSettlement = () => {
    let arr = checkboxId.filter((item) => item !== 'all');
    let bodyDataArr = arr.filter((item) => item !== undefined);
    if (bodyDataArr?.length > 0) {
      handleStateChange('isSettlementButtonAlert', true)
    } else {
      modalNotification({
        type: 'error',
        message: t('text.report.errorMarkAsMessage'),
      });
    }
  };

  const getMembershipAmountDetails = async (filterData, isClick = false) => {
    if (isClick && isFirstTimeTotalAmount) {
      setState({
        ...state,
        isShowTotalAmount: true,
      });
      setIsFirstTimeTotalAmount(false);
      return;
    }
    setState({
      ...state,
      loader: true,
    });
    let filterValues = {
      purchaseFrom: filterValue?.purchaseFrom || '',
      purchaseTo: filterValue?.purchaseTo || '',
      location: filterValue?.location || '',
      limit: sizePerPage,
      offset: sizePerPage * (page - 1),
      jobNumber: filterData?.jobNumber || undefined,
      customerName: filterData?.customerName || undefined,
      customerEmail: filterData?.customerEmail || undefined,
      phoneNumber: filterData?.phoneNumber || undefined,
      purchasedBy: filterData?.purchasedBy || undefined,
      membershipType: filterData?.membershipType || undefined,
      planType: filterData?.planType || undefined,
      durationType: filterData?.durationType || undefined,
      planAmount: filterData?.planAmount || undefined,
      paymentMethod: filterData?.paymentMethod || undefined,
      paymentStatus: filterData?.paymentStatus || undefined,
    };
    const queryParams = { ...filterValues, ...(filterData ? filterData : {}) };

    await getApiData({
      service: getMembershipHistoryAmountDetailsService,
      setData: (data) => {
        setJobAmountDetails(data ? data : {});
      },
      queryParams,
    });
    setState({
      ...state,
      loader: false,
    });
  };


  const emailFormHandler = async (value) => {
    const queryParams = { email: value?.email };
    try {
      const response = await sendRecieptEmailService(membershipHistoryId,queryParams);
      const { success, message } = response;
      if (success && message) {
        modalNotification({
          type: 'success',
          message,
        });
        handleStateChange('isShowModal', null);
      }
    } catch (error) {
      logger(error);
    }
  };

  let showModalContent = {
    sendEmail: (
      <SendEmailForm
        onSubmit={emailFormHandler}
        onCancel={() => handleStateChange('isShowModal', null)}
      />
    ),
  }

  const columns = Columns.memberShipHistoryColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    onCheckAllChange,
    handleRecipt,
    sizePerPage,
    settledCheck: settledCheck,
    handleAlert,
    filterObj: { id: customerId, name: customerName },
    isShowSettle: !permissionContext?.arr?.includes(getActions('VIEW').value),
  });

  return (
    <>
      <PageContainerWrapper
        heading={t('text.memberShip.membershipHisotryHeading')}
        breadcrumb={breadcrumb}
      >
        <Checkbox.Group
          style={{
            width: '100%',
          }}
          value={checkboxId}
        >
          <div className="">
            <div className="globalFilters">
              <div className="card ">
                <div className="card-inner">
                  <div
                    className={`globalFilters_inner ${globalFilter ? 'filterToggle' : ''
                      }`}
                  >
                    <div className="mb-3 d-block d-xl-none">
                      <Link to="#" onClick={() => BackBtnFilter()}>
                        <em className="icon ni ni-arrow-long-left me-1"></em>
                        {t('text.common.back')}
                      </Link>
                    </div>
                    <MembershipHistoryFilterForm
                      data={true}
                      onSubmit={onFilterSubmit}
                      onReset={onReset}
                      filterValue={filterValue}
                      setValues={setValues}
                      values={values}
                    />
                  </div>
                  <div
                    className={` ${globalFilter ? 'toggle-overlay' : ''} `}
                    onClick={() => BackBtnFilter()}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <TablePlus
            hasLimit
            noOfPage={noOfPages}
            sizePerPage={sizePerPage}
            page={page}
            count={count}
            tableData={data}
            tableColumns={columns}
            selectRow={true}
            onFilterResetChange={onTableResetFilter}
            tableLoader={isLoading}
            filter={filter}
            sortBy={sortBy}
            sortType={sortType}
            showAction={false}
            rowClassName={(row) =>
              row?.settle === 1
                ? 'setteled-class '
                : ''
            }
          />
        </Checkbox.Group>
        <div className="text-end my-4 d-flex align-items-start flex-wrap justify-content-end">
          {data?.length > 0 && (
            <CommonButton
              className="btn btn-primary mb-2 mb-sm-0"
              onClick={() => {
                getMembershipAmountDetails(filter, true);
              }}
              disabled={isLoading}
            >
              {t('text.report.membershipJobAmountDetail')}
            </CommonButton>
          )}
          {settledCheck && (
            <CommonButton
              loading={settledLoading}
              className="btn btn-secondary ms-2"
              onClick={handleMarkAsSettlement}
            >
              {t('text.report.markAsSettled')}
            </CommonButton>
          )}
        </div>
        {state.isShowTotalAmount ? (
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                {state?.loader === true ? (
                  <div
                    className="text-center"
                    style={{
                      minHeight: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    <h5 className="title">Total Earning Details</h5>
                    <div className="common-table">
                      <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <Table
                          pagination={false}
                          rowKey="id"
                          columns={totalJobsAmountDetailsColumn}
                          dataSource={
                            Object.values(jobAmountDetails)?.length > 0
                              ? getTotalJobsAmountDetailsData()
                              : []
                          }
                        />{' '}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {state?.isAlertVisible && (
          <SweetAlert
            title={t('text.common.areYouSure')}
            text={t('text.memberShip.youWantToSettle')}
            show={state.isAlertVisible}
            icon="warning"
            showCancelButton
            confirmButtonText="Yes"
            cancelButtonText="No"
            setIsAlertVisible={(value) =>
              handleStateChange('isAlertVisible', value)
            }
            showLoaderOnConfirm={state.isLoading}
            loading={state.isLoading}
            onConfirmAlert={onConfirmAlert}
          />
        )}
        {state?.isSettlementButtonAlert && (
          <SweetAlert
            title={t('text.common.areYouSure')}
            text={'You want to settle these payments'}
            show={state.isSettlementButtonAlert}
            icon="warning"
            showCancelButton
            confirmButtonText="Yes"
            cancelButtonText="No"
            setIsAlertVisible={(value) =>
              handleStateChange('isSettlementButtonAlert', value)
            }
            showLoaderOnConfirm={state.isLoading}
            onConfirmAlert={onSettled}
          />
        )}
        {state.isShowModal && (
          <ModalWrapper
            show={state.isShowModal}
            content={showModalContent[state.isShowModal]}
            onCancel={() => handleStateChange('isShowModal', null)}
            heading={state?.heading}
          />
        )}
      </PageContainerWrapper>
    </>
  );
}

export default MembershipHistoryList;