import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export { default as MembershipList } from './Membership/List';
export const AddMembership = loadable(() => import('./Membership/Create'), {
  fallback: <FullPageLoader />,
});
export const EditMembership = loadable(() => import('./Membership/Edit'), {
  fallback: <FullPageLoader />,
});

export const ListLineItem = loadable(() => import('./LineItem/List'), {
  fallback: <FullPageLoader />,
});
export const EditLineItem = loadable(() => import('./LineItem/Edit'), {
  fallback: <FullPageLoader />,
});
export const AddLineItem = loadable(() => import('./LineItem/Create'), {
  fallback: <FullPageLoader />,
});

export const ListServiceCategory = loadable(
  () => import('./ManageService/ServiceCategory/List'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditServiceCategory = loadable(
  () => import('./ManageService/ServiceCategory/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddServiceCategory = loadable(
  () => import('./ManageService/ServiceCategory/Create'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ListSubServiceCategory = loadable(
  () => import('./ManageService/ServiceCategory/SubServiceCategory/List'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditSubServiceCategory = loadable(
  () => import('./ManageService/ServiceCategory/SubServiceCategory/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddSubServiceCategory = loadable(
  () => import('./ManageService/ServiceCategory/SubServiceCategory/Create'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListService = loadable(
  () => import('./ManageService/Services/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListAmountCalculation = loadable(
  () => import('./QuoteManagement/AmountCalculation/List'),
  {
    fallback: <FullPageLoader />,
  }
);
// export const EditAmountCalculation = loadable(() =>
//   import('./QuoteManagement/AmountCalculation/Edit')
// );
// export const AddAmountCalculation = loadable(() =>
//   import('./QuoteManagement/AmountCalculation/Create')
// );

export const ListDiscount = loadable(
  () => import('./QuoteManagement/Discount/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListBank = loadable(
  () => import('./QuoteManagement/Finance/Bank/List'),
  {
    fallback: <FullPageLoader />,
  }
);
export const EditBank = loadable(
  () => import('./QuoteManagement/Finance/Bank/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);
export const AddBank = loadable(
  () => import('./QuoteManagement/Finance/Bank/Create'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListOptionalItem = loadable(
  () => import('./QuoteManagement/OptionalItem/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListQuoteOrder = loadable(
  () => import('./QuoteManagement/QuoteOrder/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export { default as ViewImages } from './QuoteManagement/QuoteSystem/ViewImages';

export const ListQuoteSystem = loadable(
  () => import('./QuoteManagement/QuoteSystem/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddQuoteSystem = loadable(
  () => import('./QuoteManagement/QuoteSystem/Create'),
  {
    fallback: <FullPageLoader />,
  }
);

export const EditQuoteSystem = loadable(
  () => import('./QuoteManagement/QuoteSystem/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);

// export const AddRebate = loadable(() =>
//   import('./QuoteManagement/Rebate/Create')
// );
// export const EditRebate = loadable(() =>
//   import('./QuoteManagement/Rebate/Edit')
// );
export const ListRebate = loadable(
  () => import('./QuoteManagement/Rebate/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const AddHouseLocation = loadable(
  () => import('./QuoteManagement/SupplyHouseLocation/Create'),
  {
    fallback: <FullPageLoader />,
  }
);
// export const editHouseLocation = loadable(() =>
//   import('./QuoteManagement/SupplyHouseLocation/Edit')
// );
export const ListHouseLocation = loadable(
  () => import('./QuoteManagement/SupplyHouseLocation/List'),
  {
    fallback: <FullPageLoader />,
  }
);

// export const BulkUploadQuote = loadable(() =>
//   import('./QuoteManagement/UploadBulk')
// );
// export {default as ListLineItem=itemloadable(()=>import("./LineItem/List"));
