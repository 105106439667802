import React, { useState } from 'react';
import {
  ActionWrapper,
  CommonButton,
  PageContainerWrapper,
  TablePlus,
  Toggle,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import { accessRoute } from '../../../../routes/PriceManagement/index';
import {
  Columns,
  deleteMembershipService,
  getActions,
  getMembershipService,
  updateMembershipStatus,
} from '../../../../services/index.service';
import { Link, generatePath } from 'react-router-dom';
import useTable from '../../../../hooks/useTable.hook';

export default function MembershipList() {
  const { t } = useTranslation();
  const breadcrumb = Columns.memberShipBreadcrumb();
  const [state, setState] = useState({
    loader: null,
    selectedRows: [],
    submitText: 'add',
  });
  const { loader, selectedRows, submitText } = state;
  const [tableHandler, tableProps] = useTable(getMembershipService);
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    onRefresh,
    getData,
    onTableSort,
    onFilterChange,
    onFilterResetChange,
    onToggleChange,
    onConfirmDeleteAlert,
  } = tableHandler;

  const headerBtn = {
    link: {
      name: t('text.memberShip.addMembershipPlan'),
      url: `${accessRoute.MEMBERSHIP_ADD.path}?count=${count || 0}`,
    },
  };

  function onAction(e, handler) {
    e.preventDefault();
    if (handler) handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              {item?.name === 'Edit' ? (
                <Link
                  to={`${generatePath(
                    `${accessRoute.MEMBERSHIP_EDIT.path}?count=${count || 0}`,
                    {
                      id: item?.id,
                    }
                  )}`}
                >
                  <em className={item?.icon} /> {item?.name}
                </Link>
              ) : (
                <CommonButton>
                  <em className={item?.icon} /> {item?.name}
                </CommonButton>
              )}
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const columns = Columns.memberShipColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          id: props?.row?.id,
          action: getActions('EDIT').value,
        },

        {
          name: t('text.common.delete'),
          icon: 'icon ni ni-trash',
          action: getActions('DELETE').value,
          handler: () => {
            onConfirmDeleteAlert({
              id: props?.row?.id,
              text: t('text.memberShip.areYouSureToDelete'),
              service: deleteMembershipService,
              key: 'membershipPlanIds',
            });
            document.body.click();
          },
        },
      ]),
    Toggle: (props) => (
      <Toggle
        isDropDown={true}
        shouldDeleteInclude={true}
        defaultChecked={props?.row?.status}
        loading={loader === 'status-update'}
        onChange={(e) =>
          onToggleChange(
            e,
            props?.row,
            '',
            updateMembershipStatus,
            'membershipPlanIds',
            true
          )
        }
      />
    ),
  });

  function onRowSelect(e) {
    try {
      setState({ ...state, selectedRows: [...e] });
    } catch (err) {
      console.log('err', err);
    }
  }
  async function handleActionSelect(e) {
    try {
      if (e?.target?.value === 'delete') {
        await onConfirmDeleteAlert({
          id: selectedRows,
          text: t('text.memberShip.areYouSureToDelete'),
          service: deleteMembershipService,
          key: 'membershipPlanIds',
        });
        getData();
      } else {
        await onToggleChange(
          e.target.value,
          selectedRows,
          'action',
          updateMembershipStatus,
          'membershipPlanIds'
        );
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <PageContainerWrapper
        heading={t('text.memberShip.heading')}
        breadcrumb={breadcrumb}
        btn={headerBtn}
      >
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          handleSelectedRows={onRowSelect}
          handleActionSelect={handleActionSelect}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow={true}
          onFilterResetChange={onFilterResetChange}
          tableLoader={isLoading}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
      </PageContainerWrapper>
    </>
  );
}
