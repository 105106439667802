import { CUSTOMER_APP_ENDPOINT } from '../../apiEndpoints/CustomerApp/index.endpoint';
import { COMMON_ENDPOINT } from '../../apiEndpoints/Common/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getTipsOfMonth = async ({
  queryParams = {},
  // type
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getCustomerApp,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data) {
      const tips = [];
      let i = 1;
      while (i !== 4) {
        if (res?.data.hasOwnProperty(`tip${i}Title`)) {
          tips.push({
            tipTitle: res.data[`tip${i}Title`],
            tipDescription: res.data[`tip${i}Description`],
            tipUrl: res.data[`tip${i}Url`],
          });
        }
        i++;
      }
      return tips;
    }
  } catch (err) {
    logger(err);
  }
};

export const getFinanceOption = async ({
  queryParams = {},
  // type
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getCustomerApp,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.success) {
      return res;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getServiceProvider = async ({
  queryParams = { scope: 'activeUser' },
  // type,
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getCustomerServiceProvider,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.success) {
      return res.data.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getMyAccount = async () => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.myAccount,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const forgetPassword = async ({ body }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.forgetPassword,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const resetPassword = async ({ body }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.resetPassword,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateProfile = async ({ body }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.updateCustomer,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getPages = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getPage,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getFAQs = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getFaqs,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const ContactUsService = async ({ body }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.contactUs,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getCity = async ({
  queryParams = {},
  // type
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.city,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.success) {
      return res;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getState = async ({
  queryParams = {},
  // type
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.state,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.success) {
      return res;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getCountry = async ({
  queryParams = {},
  // type
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.country,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.success) {
      return res;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getOnGoingServiceCards = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getOnGoingService,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getCustomerProviderListService = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getServiceProvidersList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getUpcomingCard = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getUpcomingCard,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getServiceProviderDetailService = async (id, type) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getProviderDetails(id),
      queryParams: {},
    };
    if (type) {
      payload.queryParams.requestType = type;
    }
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getPastCard = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getPastCard,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getRequestCard = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getRequestCard,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const MakeProviderFavorite = async ({ bodyData }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.providerFavorite,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getOnGoingServiceById = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.viewOnGoingService,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const addContactForm = async ({ bodyData }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.providerContactForm,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getRequestById = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.viewRequest,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const createNewCard = async ({ bodyData }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.newCardRequest,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getAllUserCard = async ({ queryParams }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getCardDetails,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const deleteSingleCard = async ({ id }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.deleteCard(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updatePromoCode = async (promoCode) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.updatePromoCode(promoCode),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getTimeSlotLists = async (queryParams) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getSlot,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const postBookingEmergencyFree = async (bodyData) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.bookingEmergencyFree,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getCustomerBookingList = async (queryParams) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getBookingLists,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putCustomerBookingCancel = async ({
  bookingId,
  declineReason = '',
}) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.putBookingCancel(bookingId),
      bodyData: { declineReason },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const putCustomerBookingApprove = async ({
  bookingId,
  customerSignature,
  isGuest,
  guestToken,
  guestRefToken,
}) => {
  try {
    let endPoint = isGuest
      ? CUSTOMER_APP_ENDPOINT.putGuestApproveBooking(bookingId)
      : CUSTOMER_APP_ENDPOINT.putApproveBooking(bookingId);

    const payload = {
      ...endPoint,
      bodyData: {
        customerSignature,
      },
      // ...(guestRefToken &&
      //   isGuest && { guestToken: guestRefToken || guestToken }),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getBookingDetailsById = async (bookingId, isGuest) => {
  try {
    let endPoint = isGuest
      ? CUSTOMER_APP_ENDPOINT.getGuestBookingDetailsById(bookingId)
      : CUSTOMER_APP_ENDPOINT.getBookingDetailsById(bookingId);

    const payload = {
      ...endPoint,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getServiceProviderLists = async (queryParams) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getServicerProviderList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getProviderDetailsById = async (id) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getProviderDetailsById(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getBookingReviewById = async (id) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getBookingReviewById(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getBookingReviewLists = async (queryParams) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.customerBookingReview,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const postCustomerPayNow = async (bodyData) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.postCustomerPayNow,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const postBookingReview = async (bodyData) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.postBookingReview,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getQuoteOptionListService = async (
  bookingId,
  queryParams,
  isGuest
) => {
  try {
    let endPoint = isGuest
      ? CUSTOMER_APP_ENDPOINT.getGuestQuoteOptionList(bookingId)
      : CUSTOMER_APP_ENDPOINT.getQuoteOptionList(bookingId);

    const payload = {
      ...endPoint,
      ...(queryParams && { queryParams: queryParams }),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addQuoteService = async ({
  bookingId,
  bodyData,
  isGuest,
  guestRefToken,
}) => {
  try {
    let endPoint = isGuest
      ? CUSTOMER_APP_ENDPOINT.addGuestQuote(bookingId)
      : CUSTOMER_APP_ENDPOINT.addQuote(bookingId);

    const payload = {
      ...endPoint,
      bodyData,
      ...(isGuest && { queryParams: { ref: guestRefToken } }),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const setCustomerDefaultAddress = async ({
  addressId,
  }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.setCustomerDefaultAddress(addressId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getRetingReviewById = async (bookingId) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getRetingReview(bookingId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getReceiptService = async (bookingId, email) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getReceipt(bookingId),
      queryParams: { email },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getNotificationService = async (queryParams) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getNotification,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getNotificationCountService = async () => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getNotificationCount,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getBookingStatusService = async (bookingStatusId) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getBookingStatus(bookingStatusId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getBookingRemainingAmountService = async (bookingId) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getBookingRemainingAmount(bookingId),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getNCACustomerBeforeLoginPermissionService = async (code) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getNCACustomerBeforeLoginPermission(code),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getNCACustomerAfterLoginPermissionService = async () => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getNCACustomerAfterLoginPermission,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getBookingLoader = async () => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.getBookingLoader,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const willCallList = async (bodyData) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.willCallList,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getHomeContentService = async () => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT?.getHomeContent,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const quoteDecline = async ({
  bookingId,
  declineReason = '',
  isGuest,
  guestToken,
  guestRefToken,
}) => {
  try {
    let endPoint = isGuest
      ? CUSTOMER_APP_ENDPOINT.bookingGuestDecline(bookingId)
      : CUSTOMER_APP_ENDPOINT.bookingDecline(bookingId);

    const payload = {
      ...endPoint,
      bodyData: { declineReason },
      // ...(guestRefToken &&
      //   isGuest && { guestToken: guestRefToken || guestToken }),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
