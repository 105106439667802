import { Outlet } from 'react-router-dom';
import {
  AddMembership,
  EditMembership,
  MembershipList,
  AddBank,
  AddHouseLocation,
  AddQuoteSystem,
  AddSubServiceCategory,
  EditBank,
  EditLineItem,
  EditQuoteSystem,
  EditServiceCategory,
  EditSubServiceCategory,
  ListBank,
  ListDiscount,
  ListHouseLocation,
  ListLineItem,
  ListOptionalItem,
  ListQuoteOrder,
  ListQuoteSystem,
  ListRebate,
  ListService,
  ListServiceCategory,
  ListSubServiceCategory,
  ViewImages,
  ListAmountCalculation,
} from '../../views/PriceManagement';
import CreateLineItem from '../../views/PriceManagement/LineItem/Create';
import CreateServiceCategory from '../../views/PriceManagement/ManageService/ServiceCategory/Create';
import ListOption from '../../views/PriceManagement/QuoteManagement/Finance/Option/List';
import EditHouseLocation from '../../views/PriceManagement/QuoteManagement/SupplyHouseLocation/Edit';
import BulkUpload from '../../views/PriceManagement/QuoteManagement/UploadBulk';
import { getActions } from '../../services/Auth/index.service';

export default function route() {
  return [
    {
      private: true,
      name: 'Price Management',
      key: 'priceManagement',
      belongsToSidebar: true,
      submenu: true,
      moduleKeys: [
        accessRoute.LIST_SERVICE_CATEGORY.moduleKey,
        accessRoute.LIST_SERVICE.moduleKey,
        accessRoute.LINE_ITEMS.moduleKey,
        accessRoute.UPLOAD_BULK_DATA.moduleKey,
        accessRoute.OPTIONAL_ITEMS.moduleKey,
        accessRoute.QUOTE_SYSTEM.moduleKey,
        accessRoute.QUOTE_ORDER.moduleKey,
        accessRoute.AMOUNT_CALCULATION.moduleKey,
        accessRoute.LIST_DISCOUNT.moduleKey,
        accessRoute.LIST_REBATE.moduleKey,
        accessRoute.LIST_HOUSE_LOCATION.moduleKey,
        accessRoute.LIST_FINANCING_BANK.moduleKey,
        accessRoute.LIST_FINANCING_OPTION.moduleKey,
        accessRoute.MEMBERSHIP.moduleKey,
      ],
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-coin-alt-fill" />
        </span>
      ),
      element: <Outlet />,
      children: [
        {
          private: true,
          name: 'Manage Services',
          key: 'priceManagement.manage-services',
          belongsToSidebar: true,
          element: <Outlet />,
          moduleKeys: [
            accessRoute.LIST_SERVICE_CATEGORY.moduleKey,
            accessRoute.LIST_SERVICE.moduleKey,
          ],
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.LIST_SERVICE_CATEGORY.path,
              name: 'Service Category',

              belongsToSidebar: true,
              element: <ListServiceCategory />,
              ...accessRoute.LIST_SERVICE_CATEGORY,
              key: 'priceManagement.manage-services.serviceCategory.list',
            },
            {
              private: true,
              path: accessRoute.ADD_SERVICE_CATEGORY.path,
              name: 'Add Service Category',

              belongsToSidebar: false,
              element: <CreateServiceCategory />,
              ...accessRoute.ADD_SERVICE_CATEGORY,
              key: 'priceManagement.manage-services.serviceCategory.list',
            },
            {
              private: true,
              path: accessRoute.EDIT_SERVICE_CATEGORY.path,
              name: 'Edit Service Category',

              belongsToSidebar: false,
              element: <EditServiceCategory />,
              ...accessRoute.EDIT_SERVICE_CATEGORY,
              key: 'priceManagement.manage-services.serviceCategory.list',
            },
            {
              private: true,
              path: accessRoute.LIST_SUB_SERVICE_CATEGORY.path,
              name: 'Service Category',

              belongsToSidebar: false,
              element: <ListSubServiceCategory />,
              ...accessRoute.LIST_SUB_SERVICE_CATEGORY,
              key: 'priceManagement.manage-services.serviceCategory.list',
            },
            {
              private: true,
              path: accessRoute.ADD_SUB_SERVICE_CATEGORY.path,
              name: 'Add Sub Service Category',

              belongsToSidebar: false,
              element: <AddSubServiceCategory />,
              ...accessRoute.ADD_SUB_SERVICE_CATEGORY,
              key: 'priceManagement.manage-services.serviceCategory.list',
            },
            {
              private: true,
              path: accessRoute.EDIT_SUB_SERVICE_CATEGORY.path,
              name: 'Add Sub Service Category',

              belongsToSidebar: false,
              element: <EditSubServiceCategory />,
              ...accessRoute.EDIT_SUB_SERVICE_CATEGORY,
              key: 'priceManagement.manage-services.serviceCategory.list',
            },
            {
              private: true,
              path: accessRoute.LIST_SERVICE.path,
              name: 'Services',
              action: accessRoute.LIST_SERVICE.action,

              belongsToSidebar: true,
              element: <ListService />,
              ...accessRoute.LIST_SERVICE,
              key: 'priceManagement.manage-services.services',
            },
          ],
        },
        {
          private: true,
          path: accessRoute.LINE_ITEMS.path,
          name: 'Line Items',
          key: 'priceManagement.line',
          belongsToSidebar: true,
          element: <ListLineItem />,
          ...accessRoute.LINE_ITEMS,
        },
        {
          private: true,
          path: accessRoute.ADD_LINE_ITEMS.path,
          name: 'Add Line Items',
          key: 'priceManagement.line',
          belongsToSidebar: false,
          element: <CreateLineItem />,
          ...accessRoute.ADD_LINE_ITEMS,
        },
        {
          private: true,
          path: accessRoute.EDIT_LINE_ITEMS.path,
          name: 'Edit Line Items',
          key: 'priceManagement.line',
          belongsToSidebar: false,
          element: <EditLineItem />,
          ...accessRoute.EDIT_LINE_ITEMS,
        },
        {
          private: true,
          name: 'Quote Management',
          key: 'priceManagement.quote',
          belongsToSidebar: true,
          element: <Outlet />,
          submenu: true,
          moduleKeys: [
            accessRoute.UPLOAD_BULK_DATA.moduleKey,
            accessRoute.OPTIONAL_ITEMS.moduleKey,
            accessRoute.QUOTE_SYSTEM.moduleKey,
            accessRoute.QUOTE_ORDER.moduleKey,
            accessRoute.AMOUNT_CALCULATION.moduleKey,
            accessRoute.LIST_DISCOUNT.moduleKey,
            accessRoute.LIST_REBATE.moduleKey,
            accessRoute.LIST_HOUSE_LOCATION.moduleKey,
            accessRoute.LIST_FINANCING_BANK.moduleKey,
            accessRoute.LIST_FINANCING_OPTION.moduleKey,
          ],
          children: [
            {
              private: true,
              path: accessRoute.UPLOAD_BULK_DATA.path,
              name: 'Upload Bulk Data',
              key: 'priceManagement.quote.bulk-upload',
              belongsToSidebar: true,
              element: <BulkUpload />,
              ...accessRoute.UPLOAD_BULK_DATA,
            },
            {
              private: true,
              path: accessRoute.OPTIONAL_ITEMS.path,
              name: 'Optional Items',
              key: 'priceManagement.quote.optional',
              belongsToSidebar: true,
              element: <ListOptionalItem />,
              ...accessRoute.OPTIONAL_ITEMS,
            },
            {
              private: true,
              path: accessRoute.QUOTE_SYSTEM.path,
              name: 'Quote System',
              key: 'priceManagement.quote.quote-management',
              belongsToSidebar: true,
              element: <ListQuoteSystem />,
              ...accessRoute.QUOTE_SYSTEM,
            },
            {
              private: true,
              path: accessRoute.ADD_QUOTE_SYSTEM.path,
              name: 'Add Quote System',
              key: 'priceManagement.quote.quote-management',
              belongsToSidebar: false,
              element: <AddQuoteSystem />,
              ...accessRoute.ADD_QUOTE_SYSTEM,
            },
            {
              private: true,
              path: accessRoute.EDIT_QUOTE_SYSTEM.path,
              name: 'Edit Quote System',
              key: 'priceManagement.quote.quote-management',
              belongsToSidebar: false,
              element: <EditQuoteSystem />,
              ...accessRoute.EDIT_QUOTE_SYSTEM,
            },
            {
              private: true,
              path: accessRoute.QUOTE_IMAGE_VIEW.path,
              name: 'View Image',
              key: 'priceManagement.quote.quote-management',
              belongsToSidebar: false,
              element: <ViewImages />,
              ...accessRoute.QUOTE_IMAGE_VIEW,
            },
            {
              private: true,
              path: accessRoute.QUOTE_ORDER.path,
              name: accessRoute.QUOTE_ORDER.name,
              key: 'priceManagement.quote.quote-order',
              belongsToSidebar: true,
              element: <ListQuoteOrder />,
              ...accessRoute.QUOTE_ORDER,
            },
            {
              private: true,
              path: accessRoute.AMOUNT_CALCULATION.path,
              name: 'Amount Calculation',
              key: 'priceManagement.quote.amount-calculation',
              belongsToSidebar: true,
              // icon: accessRoute.AMOUNT_CALCULATION.icon,
              element: <ListAmountCalculation />,
              ...accessRoute.AMOUNT_CALCULATION,
            },
            {
              private: true,
              path: accessRoute.LIST_DISCOUNT.path,
              name: 'Discount',
              key: 'priceManagement.quote.discount',
              belongsToSidebar: true,
              element: <ListDiscount />,
              ...accessRoute.LIST_DISCOUNT,
            },
            {
              private: true,
              path: accessRoute.LIST_REBATE.path,
              name: 'Rebate',
              key: 'priceManagement.quote.rebate',
              belongsToSidebar: true,
              // icon: accessRoute.LIST_REBATE.icon,
              element: <ListRebate />,
              ...accessRoute.LIST_REBATE,
            },
            {
              private: true,
              path: accessRoute.LIST_HOUSE_LOCATION.path,
              name: 'Supply House Location',
              key: 'priceManagement.quote.house-location',
              belongsToSidebar: true,
              // icon: accessRoute.LIST_HOUSE_LOCATION.icon,
              element: <ListHouseLocation />,
              ...accessRoute.LIST_HOUSE_LOCATION,
            },
            {
              private: true,
              path: accessRoute.ADD_HOUSE_LOCATION.path,
              name: 'Add Supply House Location',
              key: 'priceManagement.quote.house-location',
              belongsToSidebar: false,
              // icon: accessRoute.ADD_HOUSE_LOCATION.icon,
              element: <AddHouseLocation />,
              ...accessRoute.ADD_HOUSE_LOCATION,
            },
            {
              private: true,
              path: accessRoute.EDIT_HOUSE_LOCATION.path,
              name: 'Edit Supply House Location',
              key: 'priceManagement.quote.house-location',
              belongsToSidebar: false,
              // icon: accessRoute.EDIT_HOUSE_LOCATION.icon,
              element: <EditHouseLocation />,
              ...accessRoute.EDIT_HOUSE_LOCATION,
            },
            {
              private: true,
              name: 'Financing',
              key: 'priceManagement.quote.financing',
              belongsToSidebar: true,
              // icon: accessRoute.DASHBOARD.icon,
              element: <Outlet />,
              submenu: true,
              moduleKeys: [
                accessRoute.LIST_FINANCING_BANK.moduleKey,
                accessRoute.LIST_FINANCING_OPTION.moduleKey,
              ],
              children: [
                {
                  private: true,
                  path: accessRoute.LIST_FINANCING_BANK.path,
                  name: 'Financing Bank',
                  key: 'priceManagement.quote.financing.bank',
                  belongsToSidebar: true,
                  // icon: accessRoute.LIST_FINANCING_BANK.icon,
                  element: <ListBank />,
                  ...accessRoute.LIST_FINANCING_BANK,
                },
                {
                  private: true,
                  path: accessRoute.ADD_FINANCING_BANK.path,
                  name: 'Add Financing Bank',
                  key: 'priceManagement.quote.financing.bank',
                  belongsToSidebar: false,
                  // icon: accessRoute.ADD_FINANCING_BANK.icon,
                  element: <AddBank />,
                  ...accessRoute.ADD_FINANCING_BANK,
                },
                {
                  private: true,
                  path: accessRoute.EDIT_FINANCING_BANK.path,
                  name: 'Edit Financing Bank',
                  key: 'priceManagement.quote.financing.bank',
                  belongsToSidebar: false,
                  // icon: accessRoute.EDIT_FINANCING_BANK.icon,
                  element: <EditBank />,
                  ...accessRoute.EDIT_FINANCING_BANK,
                },
                {
                  private: true,
                  path: accessRoute.LIST_FINANCING_OPTION.path,
                  name: 'Financing Option',
                  key: 'priceManagement.quote.financing.finance-option',
                  belongsToSidebar: true,
                  // icon: accessRoute.LIST_FINANCING_OPTION.icon,
                  element: <ListOption />,
                  ...accessRoute.LIST_FINANCING_OPTION,
                },
              ],
            },
          ],
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP.path,
          name: accessRoute.MEMBERSHIP.name,
          key: accessRoute.MEMBERSHIP.key,
          belongsToSidebar: true,
          // icon: accessRoute.MEMBERSHIP.icon,
          element: <MembershipList />,
          ...accessRoute.MEMBERSHIP,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_ADD.path,
          name: 'Add',
          key: accessRoute.MEMBERSHIP_ADD.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP_ADD.icon,
          element: <AddMembership />,
          ...accessRoute.MEMBERSHIP_ADD,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_EDIT.path,
          name: 'Edit',
          key: accessRoute.MEMBERSHIP_EDIT.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP_EDIT.icon,
          element: <EditMembership />,
          ...accessRoute.MEMBERSHIP_EDIT,
        },
      ],
    },
  ];
}

export const accessRoute = {
  LIST_SERVICE_CATEGORY: {
    path: '/admin/service-category',
    action: getActions('LIST').value,
    moduleKey: 'service-category',
      key: 'service-category',
      order: 5,
  },
  ADD_SERVICE_CATEGORY: {
    path: '/admin/service-category/add',
    name: 'Add Service Category',
    action: getActions('CREATE').value,
    moduleKey: 'service-category',
    key: 'service-category',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-coin-alt-fill" />
    //   </span>
    // ),
  },
  EDIT_SERVICE_CATEGORY: {
    path: '/admin/service-category/edit/:id',
    name: 'Add Service Category',
    action: getActions('EDIT').value,
    moduleKey: 'service-category',
    key: 'service-category',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-coin-alt-fill" />
    //   </span>
    // ),
  },
  LIST_SUB_SERVICE_CATEGORY: {
    path: '/admin/subservice-category/:id',
    name: 'Service Category',
    action: getActions('LIST').value,
    moduleKey: 'service-category',
    key: 'service-category',
  },
  ADD_SUB_SERVICE_CATEGORY: {
    path: '/admin/subservice-category/add/:id',
    name: 'Add Service Category',
    action: getActions('LIST').value,
    moduleKey: 'service-category',
    key: 'service-category',
  },
  EDIT_SUB_SERVICE_CATEGORY: {
    path: '/admin/subservice-category/edit/:subcategoryId/:id',
    name: 'Add Service Category',
    action: getActions('EDIT').value,
    moduleKey: 'service-category',
    key: 'service-category',
  },
  LIST_SERVICE: {
    path: '/admin/services-type',
    action: getActions('LIST').value,
    moduleKey: 'services',
    key: 'servicesType',
    order: 6,
  },
  LINE_ITEMS: {
    path: '/admin/line-items',
    name: 'Line Items',
    action: getActions('LIST').value,
    moduleKey: 'line-items',
    key: 'priceManagement.line',
    order: 32,
  },
  ADD_LINE_ITEMS: {
    path: '/admin/line-items/add',
    name: 'Add Line Items',
    action: getActions('CREATE').value,
    moduleKey: 'line-items',
    key: 'priceManagement.line',
  },
  EDIT_LINE_ITEMS: {
    path: '/admin/line-items/edit/:id',
    name: 'Edit Line Items',
    action: getActions('EDIT').value,
    moduleKey: 'line-items',
    key: 'priceManagement.line',
  },
  UPLOAD_BULK_DATA: {
    path: '/admin/upload-data',
    name: 'Upload Bulk Data',
    action: getActions('LIST').value,
    moduleKey: 'upload-bulk-data',
    key: 'priceManagement.quote.upload-bulk-data',
    order: 37,
  },
  OPTIONAL_ITEMS: {
    path: '/admin/optional-items',
    name: 'Optional Items',
    action: getActions('LIST').value,
    moduleKey: 'optional-items',
    key: 'priceManagement.quote.optional-items',
    order: 47,
  },
  QUOTE_SYSTEM: {
    path: '/admin/quote-management',
    name: 'Quote System',
    action: getActions('LIST').value,
    moduleKey: 'quote-system',
    key: 'priceManagement.quote.quote-system',
    order: 38,
  },
  ADD_QUOTE_SYSTEM: {
    path: '/admin/quote-management/add',
    name: 'Add Quote System',
    action: getActions('CREATE').value,
    moduleKey: 'quote-system',
    key: 'priceManagement.quote.quote-system',
  },
  EDIT_QUOTE_SYSTEM: {
    path: '/admin/quote-management/edit/:id',
    name: 'Edit Quote System',
    action: getActions('EDIT').value,
    moduleKey: 'quote-system',
    key: 'priceManagement.quote.quote-system',
  },
  QUOTE_IMAGE_VIEW: {
    path: '/admin/quote-management/view-image',
    name: 'View Image',
    action: getActions('LIST').value,
    moduleKey: 'quote-system',
    key: 'priceManagement.quote.priceManagement.quote.quote-system',
  },
  QUOTE_ORDER: {
    path: '/admin/quote-order',
    name: 'Quote Order & Admin Profit',
    action: getActions('LIST').value,
    moduleKey: 'quote-order-admin-profit',
    key: 'priceManagement.quote.quote-order-admin-profit',
    order: 43,
  },
  AMOUNT_CALCULATION: {
    path: '/admin/amount-calculation',
    name: 'Amount Calculation',
    action: getActions('LIST').value,
    moduleKey: 'amount-calculation',
    key: 'priceManagement.quote.amount-calculation',
    order: 39,
  },
  LIST_DISCOUNT: {
    path: '/admin/discount',
    name: 'Discount',
    action: getActions('LIST').value,
    moduleKey: 'discount',
    key: 'priceManagement.quote.discount',
    order: 44,
  },
  LIST_REBATE: {
    path: '/admin/rebate',
    name: 'Rebate',
    action: getActions('LIST').value,
    moduleKey: 'rebate',
    key: 'priceManagement.quote.rebate',
    order: 45,
  },
  LIST_HOUSE_LOCATION: {
    path: '/admin/equipment-location',
    name: 'Supply House Location',
    action: getActions('LIST').value,
    moduleKey: 'supply-house-location',
    key: 'priceManagement.quote.supply-house-location',
    order: 46,
  },
  ADD_HOUSE_LOCATION: {
    path: '/admin/equipment-location/add',
    name: 'Add Supply House Location',
    action: getActions('CREATE').value,
    moduleKey: 'supply-house-location',
    key: 'priceManagement.quote.supply-house-location',
  },
  EDIT_HOUSE_LOCATION: {
    path: '/admin/equipment-location/edit/:id',
    name: 'Edit Supply House Location',
    action: getActions('EDIT').value,
    moduleKey: 'supply-house-location',
    key: 'priceManagement.quote.supply-house-location',
  },
  LIST_FINANCING_BANK: {
    path: '/admin/financing-bank',
    name: 'Financing Bank',
    action: getActions('LIST').value,
    moduleKey: 'financing-bank',
    key: 'priceManagement.quote.financing.financing-bank',
    order: 41,
  },
  ADD_FINANCING_BANK: {
    path: '/admin/financing-bank/add',
    name: 'Add Financing Bank',
    action: getActions('CREATE').value,
    moduleKey: 'financing-bank',
    key: 'priceManagement.quote.financing.financing-bank',
  },
  EDIT_FINANCING_BANK: {
    path: '/admin/financing-bank/edit/:id',
    name: 'Edit Financing Bank',
    action: getActions('EDIT').value,
    moduleKey: 'financing-bank',
    key: 'priceManagement.quote.financing.financing-bank',
  },
  LIST_FINANCING_OPTION: {
    path: '/admin/financing-option',
    name: 'Financing Option',
    action: getActions('LIST').value,
    moduleKey: 'financing-option',
    key: 'priceManagement.quote.financing.financing-option',
    order: 42,
  },
  MEMBERSHIP: {
    path: '/admin/membership',
    action: getActions('LIST').value,
    key: 'priceManagement.membershipList',
    name: 'Membership Plans',
    moduleKey: 'membership',
    order: 31,
  },
  MEMBERSHIP_ADD: {
    path: '/admin/membership/add',
    action: getActions('CREATE').value,
    key: 'priceManagement.membershipList',
    moduleKey: 'membership',
  },
  MEMBERSHIP_EDIT: {
    path: '/admin/membership/edit/:id',
    action: getActions('EDIT').value,
    key: 'priceManagement.membershipList',
    moduleKey: 'membership',
  },
};

/******Return should be list of children routes**** */
export const getChildrenRoutes = () => {
  return [...route()[0]?.children];
};
